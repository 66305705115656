import type { RouteRecordRaw } from 'vue-router';

import { LayoutType, RouteName } from '@/shared/config/router';

export const route: RouteRecordRaw = {
  path: '',
  name: RouteName.HOME,
  component: () => import('@/pages/home/ui/home.vue'),
  meta: {
    layout: LayoutType.DEFAULT,
  },
};
