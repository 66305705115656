import type { App } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import { routes } from '@/app/router';

export const withRouter = (app: App): void => {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(_, __, savedPosition) {
      return savedPosition || { top: 0 };
    },
  });
  app.use(router);
};
