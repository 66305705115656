import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

import { env } from '@/shared/env';

export const withSentry = (app: App): void => {
  if (!env.SENTRY_DSN) {
    return;
  }

  Sentry.init({
    app,
    environment: env.SENTRY_ENVIRONMENT,
    dsn: env.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router: app.config.globalProperties.$router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  });
};
