export const REQUIRED_MESSAGE = 'Обязательное поле';
export const REGEX_MESSAGE = 'Поле должно иметь верный формат';
export const createMinLengthMessage = (minLength: number): string =>
  `Поле должно содержать не менее ${minLength} символов`;
export const createMaxLengthMessage = (maxLength: number): string =>
  `Поле должно содержать не более ${maxLength} символов`;
export const createOneFieldMoreThanSecondMessage = (secondFieldName: string): string =>
  `Поле должно иметь значение, большее чем "${secondFieldName}"`;
export const createOneFieldLessThanSecondMessage = (secondFieldName: string): string =>
  `Поле должно иметь значение, меньше чем "${secondFieldName}"`;
export const createRangeValidationMessage = (min: number, max: number): string =>
  `Значение поля должно быть в диапозоне от ${min} до ${max}`;
