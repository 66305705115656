import { formatDate } from '@mm-frontend/mithril-ui-kit';
import type { MDropItem, MOptionItem, MTableColumn } from '@mm-frontend/mithril-ui-kit';
import type { RouteRecordRaw } from 'vue-router';

import type { TransportationOrderLocalFilter } from '@/pages/transportation-orders/types.ts';
import { readableTransportationStatus, readableTransportationType } from '@/entities/transportation-orders';
import {
  TransportationOrderSortDirectionEnum,
  TransportationOrderSortFieldEnum,
  TransportationOrderStatus,
  TransportationType,
  type TransportationOrder,
  type TransportationOrderFilter,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { RouteName } from '@/shared/config/router';
import { convertDateToBackend, formatKopeckToRuble, removeUTC, secondsToReadableTime } from '@/shared/lib/utils';

export const routeName = 'transportation-orders';
export const route: RouteRecordRaw = {
  path: '',
  name: RouteName.TRANSPORTATION_ORDERS,
  component: () => import('./ui/TransportationOrders.vue'),
};

export const transportationOrderPageSize = 20;

const defaultFilter: TransportationOrderLocalFilter = {
  planFirstDepartureAtDate: '',
  planFirstDepartureAtFrom: '00:00',
  planFirstDepartureAtTo: '23:59',
  departureCity: '',
  destinationCity: '',
  transitCity: '',
  departureLogisticPoint: undefined,
  destinationLogisticPoint: undefined,
  transitLogisticPoint: undefined,
  status: [],
  number: undefined,
  carrier: undefined,
  isRoundTrip: undefined,
  type: [],
};

export const getDefaultFilter = (): TransportationOrderLocalFilter => {
  const filter = structuredClone(defaultFilter);
  const today = new Date();
  filter.planFirstDepartureAtDate = convertDateToBackend(today.setDate(today.getDate() + 1));
  return filter;
};

export const tableColumns: MTableColumn<TransportationOrder>[] = [
  {
    label: '№ заказа',
    value: 'number',
  },
  {
    label: 'Пункт отправления',
    value: 'departurePoint',
    customRender: (row) => row.schedule.firstSchedulePoint.logisticPoint?.shortName ?? '',
  },
  {
    label: 'Перевозчик',
    value: 'carrier',
    customRender: (row) => row.carrier?.companyName ?? '',
  },
  {
    label: 'Дата отправления',
    value: 'departureDate',
    customRender: (row) => formatDate(removeUTC(row.schedule.planFirstDepartureAt), 'digital', '—'),
    isSortable: true,
  },
  {
    label: 'Куб.ТС',
    value: 'cargoVolume',
    customRender: (row) => `${row.cargoVolume} м3`,
    isSortable: true,
  },
  {
    label: 'Статус заявки',
    value: 'status',
  },
  {
    label: 'Тип перевозки',
    value: 'type',
    customRender: (row) => readableTransportationType(row.type),
  },
  {
    label: 'Кол-во точек',
    value: 'pointsCount',
    customRender: (row) => String(row.schedule.schedulePointsCount),
    isSortable: true,
  },
  {
    label: 'Время в пути',
    value: 'totalDuration',
    customRender: (row) => secondsToReadableTime(row.schedule.totalDuration),
    isSortable: true,
  },
  {
    label: 'Стоимость',
    value: 'isRoundTrip',
    customRender: (row) => `${formatKopeckToRuble(row.price)}\xA0₽`,
  },
  {
    label: 'Круговой',
    value: 'isRoundTrip',
    customRender: (row) => (row.schedule.isRoundTrip ? 'Да' : 'Нет'),
  },
];

export const transportationOrdersSortFieldMap: Record<string, TransportationOrderSortFieldEnum> = {
  cargoVolume: TransportationOrderSortFieldEnum.CargoVolume,
  departureDate: TransportationOrderSortFieldEnum.SchedulePlanFirstDepartureAt,
  pointsCount: TransportationOrderSortFieldEnum.ScheduleSchedulePointsCount,
  totalDuration: TransportationOrderSortFieldEnum.ScheduleTotalDuration,
};

export const transportationOrdersSortDirectionMap: Record<string, TransportationOrderSortDirectionEnum> = {
  asc: TransportationOrderSortDirectionEnum.Asc,
  desc: TransportationOrderSortDirectionEnum.Desc,
  default: TransportationOrderSortDirectionEnum.Asc,
};

export const tripStatusOptions: MOptionItem[] = [
  {
    title: 'Да',
    id: 1,
  },
  {
    title: 'Нет',
    id: 0,
  },
];

export const transportationTypeOptions: MOptionItem[] = Object.values(TransportationType).map(
  (transportationType: TransportationType) => ({
    title: readableTransportationType(transportationType),
    id: transportationType as string,
  }),
);

export const transportationOrderStatusOptions: MOptionItem[] = Object.values(TransportationOrderStatus).map(
  (transportationOrderStatus: TransportationOrderStatus) => ({
    title: readableTransportationStatus(transportationOrderStatus),
    id: transportationOrderStatus as string,
  }),
);

export const createFilterFromLocal = (localFilter: TransportationOrderLocalFilter): TransportationOrderFilter => {
  return {
    planFirstDepartureAtDate: localFilter.planFirstDepartureAtDate,
    planFirstDepartureAtFrom: localFilter.planFirstDepartureAtFrom,
    planFirstDepartureAtTo: localFilter.planFirstDepartureAtTo,
    departureCity: localFilter.departureCity,
    destinationCity: localFilter.destinationCity,
    transitCity: localFilter.transitCity,
    departureLogisticPointCode: localFilter.departureLogisticPoint?.shortName,
    destinationLogisticPointCode: localFilter.destinationLogisticPoint?.shortName,
    transitLogisticPointCode: localFilter.transitLogisticPoint?.shortName,
    status: localFilter.status,
    number: localFilter.number,
    isRoundTrip: localFilter.isRoundTrip === undefined ? undefined : Boolean(localFilter.isRoundTrip),
    carrierId: localFilter.carrier?.id,
    type: localFilter.type,
  };
};

export const dropList: MDropItem<TransportationType>[] = [
  {
    text: 'Внутригород',
    action: TransportationType.TRANSPORTATION_TYPE_INTRACITY_TRANSPORTATION,
  },
  {
    text: 'Магистраль',
    action: TransportationType.TRANSPORTATION_TYPE_LONG_DISTANCE_TRANSPORTATION,
  },
];
