import { readableEnum, type MRadioItem, type MStatusDotColor } from '@mm-frontend/mithril-ui-kit';

import {
  DayOfWeek,
  LogisticPointStatus,
  LogisticPointType,
  OwnershipType,
} from '@/shared/api/generated-api/logistic-points/data-contracts.ts';

export const readableLogisticPointStatus = readableEnum<LogisticPointStatus>({
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_OPEN]: 'Открыт',
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_CLOSED]: 'Закрыт',
});

export const readableLogisticPointType = readableEnum<LogisticPointType>({
  [LogisticPointType.LOGISTIC_POINT_TYPE_PICKUP_POINT]: 'ПВЗ',
  [LogisticPointType.LOGISTIC_POINT_TYPE_SORTING_CENTER]: 'СЦ',
  [LogisticPointType.LOGISTIC_POINT_TYPE_CROSSDOCK]: 'Кросс-док',
  [LogisticPointType.LOGISTIC_POINT_TYPE_FULLFILLMENT]: 'ФФ',
  [LogisticPointType.LOGISTIC_POINT_TYPE_DROPOFF_POINT]: 'Дроп оф',
  [LogisticPointType.LOGISTIC_POINT_TYPE_DISTRIBUTION_CENTER]: 'Распределительный центр',
  [LogisticPointType.LOGISTIC_POINT_TYPE_SELLER]: 'Селлер',
});

export const readableLogisticPointOwnershipType = readableEnum<OwnershipType>({
  [OwnershipType.OWNERSHIP_TYPE_MM]: 'Собственный',
  [OwnershipType.OWNERSHIP_TYPE_PARTNER]: 'Партнерский',
  [OwnershipType.OWNER_SHIP_TYPE_BUSINESS_PARTNER]: 'Партнерский',
});

export const readableDayOfWeekShort = readableEnum<DayOfWeek>({
  [DayOfWeek.DAY_OF_WEEK_MONDAY]: 'Пн',
  [DayOfWeek.DAY_OF_WEEK_TUESDAY]: 'Вт',
  [DayOfWeek.DAY_OF_WEEK_WEDNESDAY]: 'Ср',
  [DayOfWeek.DAY_OF_WEEK_THURSDAY]: 'Чт',
  [DayOfWeek.DAY_OF_WEEK_FRIDAY]: 'Пт',
  [DayOfWeek.DAY_OF_WEEK_SATURDAY]: 'Сб',
  [DayOfWeek.DAY_OF_WEEK_SUNDAY]: 'Вс',
});

export const logisticPointTypeOptions: MRadioItem<LogisticPointType>[] = Object.values(LogisticPointType).map(
  (logisticPointType: LogisticPointType) => ({
    label: readableLogisticPointType(logisticPointType),
    value: logisticPointType,
  }),
);

export const logisticPointStatusOptions: MRadioItem<LogisticPointStatus>[] = Object.values(LogisticPointStatus).map(
  (logisticPointStatus: LogisticPointStatus) => ({
    label: readableLogisticPointStatus(logisticPointStatus),
    value: logisticPointStatus,
  }),
);

export const logisticPointStatusDotColorMap: Record<LogisticPointStatus, MStatusDotColor> = {
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_OPEN]: 'green',
  [LogisticPointStatus.LOGISTIC_POINT_STATUS_CLOSED]: 'red',
};

export const logisticPointScheduleDayOfWeekMap: Record<number, DayOfWeek> = {
  0: DayOfWeek.DAY_OF_WEEK_SUNDAY,
  1: DayOfWeek.DAY_OF_WEEK_MONDAY,
  2: DayOfWeek.DAY_OF_WEEK_TUESDAY,
  3: DayOfWeek.DAY_OF_WEEK_WEDNESDAY,
  4: DayOfWeek.DAY_OF_WEEK_THURSDAY,
  5: DayOfWeek.DAY_OF_WEEK_FRIDAY,
  6: DayOfWeek.DAY_OF_WEEK_SUNDAY,
};

export const MIN_SEARCH_LENGTH = 3;
