import mitt, { type Emitter, type EventType, type Handler } from 'mitt';
import { onBeforeUnmount, onMounted } from 'vue';

import type { EventsMap } from './types';

type EventManager<Events extends Record<EventType, unknown>> = Emitter<Events> & {
  once: <Key extends keyof Events>(type: Key, handler: Handler) => void;
  lifecycleSubscribe: <Key extends keyof Events>(type: Key, handler: Handler) => void;
};

const eventManagerInstance = mitt<EventsMap>();

/**
 * Создает менеджер событий, позволяющий прослушивать и генерировать события.
 * Используя библеотеку mitt
 *
 * @returns Объект, содержащий менеджер событий.
 */
const useEventManager = (): {
  eventManager: EventManager<EventsMap>;
} => {
  const eventManagerOnce: EventManager<EventsMap>['once'] = (type, handler): void => {
    const wrappedHandler: Handler = (...args) => {
      handler(...args);
      eventManagerInstance.off(type, wrappedHandler);
    };

    eventManagerInstance.on(type, wrappedHandler);
  };

  const subscribeVueMount: EventManager<EventsMap>['lifecycleSubscribe'] = (type, handler): void => {
    onMounted(() => {
      eventManagerInstance.on(type, handler);
    });

    onBeforeUnmount(() => {
      eventManagerInstance.off(type, handler);
    });
  };

  const eventManager: EventManager<EventsMap> = {
    ...eventManagerInstance,
    once: eventManagerOnce,
    lifecycleSubscribe: subscribeVueMount,
  };

  return { eventManager };
};

export { useEventManager };
