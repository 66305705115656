import { defineAsyncComponent, markRaw } from 'vue';
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { carriersRoute } from '@/pages/carriers';
import { driversRoute } from '@/pages/drivers';
import { homeRoute } from '@/pages/home';
import { loginRoute } from '@/pages/login';
import { logisticPointsRoute } from '@/pages/logistic-points';
import { modelsRoute } from '@/pages/models';
import { monitoringRoute } from '@/pages/monitoring';
import { notFoundRoute } from '@/pages/not-found';
import { RestorePasswordRoute } from '@/pages/restore-password';
import { transportationOrderRoute } from '@/pages/transportation-order';
import { transportationOrderAddRoute } from '@/pages/transportation-order-add';
import { transportationOrdersRoute, transportationOrdersRouteName } from '@/pages/transportation-orders';
import { vehiclesRoute } from '@/pages/vehicles';
import { LayoutType, RouteName } from '@/shared/config/router';
import { useAuthStore, useCacheStore } from '@/shared/config/stores';

export const layoutComponents = {
  [LayoutType.DEFAULT]: markRaw(defineAsyncComponent(() => import('@/shared/ui/layout-default/LayoutDefault.vue'))),
  [LayoutType.LOGIN]: markRaw(defineAsyncComponent(() => import('@/shared/ui/layout-login/LayoutLogin.vue'))),
};

export const routes: RouteRecordRaw[] = [
  loginRoute,
  RestorePasswordRoute,
  {
    path: '/',
    beforeEnter(to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext): void {
      if (useAuthStore().accessToken) {
        next();
      } else {
        useCacheStore().setNextRoute(to);
        next({ name: RouteName.LOGIN });
      }
    },
    children: [
      homeRoute,
      driversRoute,
      vehiclesRoute,
      modelsRoute,
      carriersRoute,
      logisticPointsRoute,
      monitoringRoute,
      {
        path: transportationOrdersRouteName,
        children: [transportationOrdersRoute, transportationOrderRoute, transportationOrderAddRoute],
      },
      notFoundRoute,
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: { name: RouteName.NOT_FOUND } },
];
