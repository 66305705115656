import type { RouteRecordRaw } from 'vue-router';

import { LayoutType, RouteName } from '@/shared/config/router';

export const route: RouteRecordRaw = {
  path: '/login',
  name: RouteName.LOGIN,
  component: () => import('./Login.vue'),

  meta: {
    layout: LayoutType.LOGIN,
  },
};
