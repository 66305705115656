import { readableEnum } from '@mm-frontend/mithril-ui-kit';
import type { MOptionItem, MStatusDotColor } from '@mm-frontend/mithril-ui-kit';
import { helpers, minValue, required } from '@vuelidate/validators';
import type { Ref } from 'vue';

import { logisticPointScheduleDayOfWeekMap } from '@/entities/logistic-points';
import {
  TransportationOrderStatus,
  TransportationType,
  type LogisticPoint,
  type LogisticPointSchedule,
  type SchedulePoint,
  type TransportationOrder,
} from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { getPluralWord, readableTimeToSeconds, removeUTC } from '@/shared/lib/utils';

import { formatISODateToTransitDate, getTimeBetweenPoints } from './lib.ts';

export const transportationStatusDotColorMap: Record<TransportationOrderStatus, MStatusDotColor> = {
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_APPROVED]: 'green',
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_CANCELED]: 'red',
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_CARRIER_SELECTION]: 'orange',
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_DRAFT]: 'gray',
};

export const readableTransportationStatus = readableEnum<TransportationOrderStatus>({
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_DRAFT]: 'Черновик',
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_CARRIER_SELECTION]: 'На\xA0согласовании',
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_APPROVED]: 'Подтверждена',
  [TransportationOrderStatus.TRANSPORTATION_ORDER_STATUS_CANCELED]: 'Отменена',
});

export const transportationOrdersPluralText = (count: number): string => {
  return `${count} ${getPluralWord(count, ['заявка', 'заявки', 'заявок'])}`;
};

export const readableTransportationType = readableEnum<TransportationType>({
  [TransportationType.TRANSPORTATION_TYPE_INTRACITY_TRANSPORTATION]: 'Внутригород',
  [TransportationType.TRANSPORTATION_TYPE_LONG_DISTANCE_TRANSPORTATION]: 'Магистраль',
});

export const timePlaceholder = '__:__';
let orderId = 1;
export const getDefaultPoint = (): SchedulePoint => {
  return {
    id: `${orderId++}`,
    logisticPointId: '',
    logisticPoint: undefined,
    position: 1,
    planArriveAt: '',
    planDepartAt: '',
  };
};

const NEAR_CLOSE_MINUTES = 30;

export const markAsDraftValidationRules: {
  cargoVolume: object;
} = {
  cargoVolume: {
    required: helpers.withMessage('', required),
    minValue: helpers.withMessage('', minValue(1)),
  },
};

export const createSendToTransporterValidationRules = (
  order: Ref<TransportationOrder | undefined>,
): {
  cargoVolume: object;
  carrierId?: object;
  contractId?: object;
  price?: object;
  schedule?: object;
} => {
  return {
    ...markAsDraftValidationRules,
    carrierId: {
      required: helpers.withMessage('', required),
    },
    contractId: {
      required: helpers.withMessage('', required),
    },
    price: {
      required: helpers.withMessage('', required),
      minValue: helpers.withMessage('', minValue(1)),
    },
    schedule: {
      points: {
        $each: helpers.forEach({
          logisticPointId: {
            required: helpers.withMessage('', required),
          },
          planArriveAt: {
            required: helpers.withMessage('', required),
            lessThanDepart: helpers.withMessage(
              'Время прибытия позже времени отправления',
              (value: string, currentObject: SchedulePoint): boolean => {
                return (
                  !value ||
                  !currentObject.planDepartAt ||
                  currentObject.isArrived ||
                  value <= currentObject.planDepartAt
                );
              },
            ),
            moreThanPreviousDepartAt: helpers.withMessage(
              'Время прибытия позже времени отправления из предыдущей точки',
              (value: string, currentObject: SchedulePoint, currentIndex: number): boolean => {
                const timeBetweenPoints = order.value
                  ? getTimeBetweenPoints(order.value.schedule.points, currentIndex)
                  : undefined;
                return (
                  currentIndex === 0 || !value || currentObject.isArrived || !timeBetweenPoints || timeBetweenPoints > 0
                );
              },
            ),
          },
          planDepartAt: {
            required: helpers.withMessage('', required),
          },
        }),
      },
    },
  };
};

const getLogisticPointSchedule = (point: LogisticPoint, day: number): LogisticPointSchedule | undefined => {
  const arrivalDateDay = logisticPointScheduleDayOfWeekMap[day];
  return point.schedule.find((s) => s.dayOfWeek === arrivalDateDay);
};

const isPointOpenedAroundTheClock = (logisticPointSchedule: LogisticPointSchedule): boolean =>
  logisticPointSchedule.openedAt === '00:00' && logisticPointSchedule.closedAt === '00:00';

const timeAtNotWorkingHoursValidator = (value: string, currentObject: SchedulePoint): boolean => {
  if (!value || !currentObject.logisticPoint || currentObject.isArrived) {
    return true;
  }

  const dateTime = formatISODateToTransitDate(removeUTC(value));
  if (!dateTime.date) {
    return true;
  }

  const dateSchedule = getLogisticPointSchedule(currentObject.logisticPoint, dateTime.date.getDay());

  if (!dateSchedule) {
    return false;
  }

  if (isPointOpenedAroundTheClock(dateSchedule)) {
    return true;
  }

  return dateTime.time >= dateSchedule.openedAt && dateTime.time < dateSchedule.closedAt;
};

export const sendToTransporterWarningValidationRules: {
  schedule?: object;
} = {
  schedule: {
    points: {
      $each: helpers.forEach({
        planArriveAt: {
          arriveAtNonWorkingHours: helpers.withMessage(
            'Время прибытия в нерабочие часы',
            timeAtNotWorkingHoursValidator,
          ),
          arriveNearClose: helpers.withMessage(
            'Время прибытия близко к закрытию',
            (value: string, currentObject: SchedulePoint): boolean => {
              if (!value || !currentObject.logisticPoint || currentObject.isArrived) {
                return true;
              }

              const arriveDateTime = formatISODateToTransitDate(removeUTC(value));
              if (!arriveDateTime.date) {
                return true;
              }

              const arrivalDateSchedule = getLogisticPointSchedule(
                currentObject.logisticPoint,
                arriveDateTime.date.getDay(),
              );

              if (!arrivalDateSchedule || isPointOpenedAroundTheClock(arrivalDateSchedule)) {
                return true;
              }

              const arriveTimeInSeconds = readableTimeToSeconds(arriveDateTime.time);
              const arriveDateClosedTime = readableTimeToSeconds(arrivalDateSchedule.closedAt);

              return (
                arriveTimeInSeconds >= arriveDateClosedTime ||
                arriveDateClosedTime - arriveTimeInSeconds > NEAR_CLOSE_MINUTES * 60
              );
            },
          ),
        },
        planDepartAt: {
          departAtNonWorkingHours: helpers.withMessage(
            'Время отправления в нерабочие часы',
            timeAtNotWorkingHoursValidator,
          ),
        },
      }),
    },
  },
};

export const transportationTypeOptions: MOptionItem[] = Object.values(TransportationType).map(
  (transportationType: TransportationType) => ({
    title: readableTransportationType(transportationType),
    id: transportationType as string,
  }),
);
