import type { MIconNames } from '@mm-frontend/mithril-ui-kit';

import { BodyType, VehicleType } from '@/shared/api/generated-api/fleets/data-contracts';
import type { ModelVehicleTypeEnum } from '@/shared/api/generated-api/transportation-orders/data-contracts';
import { readableEnum } from '@/shared/lib/utils';

import type { FormModel } from './types';

export const defaultModel: FormModel = {
  brand: '',
  model: '',
  bodyType: undefined,
  vehicleMass: undefined,
  permissibleMaximumMass: undefined,
  vehicleType: undefined,
  loadCapacity: 0,
  bodyWidth: undefined,
  bodyHeight: undefined,
  bodyDepth: undefined,
  passengersCapacity: 0,
  cargoSpace: 0,
};

export const readableVehicleType = readableEnum<VehicleType | ModelVehicleTypeEnum>({
  [VehicleType.VEHICLE_TYPE_CARGO]: 'Грузовой фургон',
  [VehicleType.VEHICLE_TYPE_TRAILER]: 'Прицеп',
  [VehicleType.VEHICLE_TYPE_CAR]: 'Легковой',
  [VehicleType.VEHICLE_TYPE_PASSENGER]: 'Пассажирский',
  [VehicleType.VEHICLE_TYPE_SPECIAL]: 'Спецтехника',
  [VehicleType.VEHICLE_TYPE_TRACTOR]: 'Тягач',
});

export const readableBodyType = readableEnum<BodyType>({
  [BodyType.BODY_TYPE_TENT]: 'Тент',
  [BodyType.BODY_TYPE_ISOMETRIC]: 'Изометрический',
  [BodyType.BODY_TYPE_OPEN]: 'Открытый',
  [BodyType.BODY_TYPE_METAL]: 'Цельнометаллический',
  [BodyType.BODY_TYPE_REFRIGERATOR]: 'Рефрижератор',
});

export const iconFromCarType = readableEnum<VehicleType | ModelVehicleTypeEnum, MIconNames>({
  [VehicleType.VEHICLE_TYPE_CARGO]: 'vehicle-truck-profile',
  [VehicleType.VEHICLE_TYPE_TRAILER]: 'transport_trailer',
  [VehicleType.VEHICLE_TYPE_CAR]: 'transport_car',
  [VehicleType.VEHICLE_TYPE_PASSENGER]: 'transport_bus',
  [VehicleType.VEHICLE_TYPE_SPECIAL]: 'transport_special',
  [VehicleType.VEHICLE_TYPE_TRACTOR]: 'transport_truck',
});
