import { refreshAccessToken } from '@mm-frontend/ops-auth';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { currentMode } from '@/shared/config/auth';
import { useAuthStore } from '@/shared/config/stores';
import { useEventManager } from '@/shared/lib/event-manager';

interface FailedRequest {
  resolve: (value: AxiosResponse) => void;
  reject: (value: AxiosError) => void;
  config: AxiosRequestConfig;
  error: AxiosError;
}

let isRefreshing = false;
const failedRequests: FailedRequest[] = [];

/**
 * Функция для запрос нового refreshToken
 *
 * Запрашивает токен
 * Если токен уже запрашивается, то добавляет вызов в стэк
 * Записывает в стор – authStore
 * Записывает в конфиг axiosInstance
 *
 * Пытается повторить запрос для всех вызовов в стэке и для первого
 *
 * В случае ошибки запроса refreshToken:
 * Очищает предыдущие значения в сторе
 * Показывает ошибку авторизации
 * Перекидывает на главную страницу
 *
 */
export const fetchRefreshToken = async (
  error: AxiosError<any, any>,
  axiosInstance: AxiosInstance,
): Promise<AxiosResponse<any, any>> => {
  const originalRequestConfig = error.config!;

  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedRequests.push({
        resolve,
        reject,
        config: originalRequestConfig,
        error: error,
      });
    });
  }

  isRefreshing = true;

  const { eventManager } = useEventManager();
  try {
    const authStore = useAuthStore();

    const user = await refreshAccessToken({
      mode: currentMode,
      refreshToken: authStore.refreshToken,
      closeErrorCallback: () => {
        eventManager.emit('user:logout');
      },
    });

    authStore.setTokens(user.accessToken, user.refreshToken);

    originalRequestConfig.headers['X-Token'] = user.accessToken;

    failedRequests.forEach(({ resolve, reject, config }) => {
      if (config.headers) {
        config.headers['X-Token'] = user.accessToken;
      }

      axiosInstance(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return axiosInstance(originalRequestConfig);
  } finally {
    isRefreshing = false;
  }
};
