import { readableEnum, type MRadioItem, type MStatusDotColor } from '@mm-frontend/mithril-ui-kit';

import {
  CarrierStatus,
  ContractStatus,
  LegalForm,
  ServiceType,
} from '@/shared/api/generated-api/carriers/data-contracts.ts';

export const readableCarrierStatusShort = readableEnum<CarrierStatus>({
  [CarrierStatus.READY]: 'Готов к участию',
  [CarrierStatus.NOT_READY]: 'Не готов к участию',
});

export const readableCarrierStatusLong = readableEnum<CarrierStatus>({
  [CarrierStatus.READY]: 'Готов к участию в перевозках',
  [CarrierStatus.NOT_READY]: 'Не готов к участию в перевозках',
});

export const carrierStatusDotColorMap: Record<CarrierStatus, MStatusDotColor> = {
  [CarrierStatus.READY]: 'green',
  [CarrierStatus.NOT_READY]: 'red',
};

export const readableServiceType = readableEnum<ServiceType>({
  [ServiceType.COURIER_TRANSPORTATION]: 'Курьерские перевозки',
  [ServiceType.INTRACITY_TRANSPORTATION]: 'Внутригородские перевозки',
  [ServiceType.LONG_DISTANCE_TRANSPORTATION]: 'Магистральная перевозка',
});

export const readableLegalForm = readableEnum<LegalForm>({
  [LegalForm.LEGAL_ENTITY]: 'Юридическое лицо',
  [LegalForm.INDIVIDUAL_ENTREPRENEUR]: 'Индивидуальный предприниматель',
});

export const contractStatusDotColorMap: Record<ContractStatus, MStatusDotColor> = {
  [ContractStatus.APPROVED]: 'green',
  [ContractStatus.DRAFT]: 'gray',
  [ContractStatus.REJECTED]: 'red',
  [ContractStatus.ON_APPROVAL]: 'persian',
  [ContractStatus.SENT_FOR_CLARIFICATION]: 'orange',
};

export const readableContractStatus = readableEnum<ContractStatus>({
  [ContractStatus.APPROVED]: 'Утвержден',
  [ContractStatus.DRAFT]: 'Черновик',
  [ContractStatus.REJECTED]: 'Отклонен',
  [ContractStatus.ON_APPROVAL]: 'На утверждении',
  [ContractStatus.SENT_FOR_CLARIFICATION]: 'Отправлен на уточнение',
});

export const MIN_SEARCH_LENGTH = 3;

export const serviceTypeOptions: MRadioItem<ServiceType>[] = Object.values(ServiceType).map(
  (serviceType: ServiceType) => ({
    label: readableServiceType(serviceType),
    value: serviceType,
  }),
);

export const carrierStatusOptions: MRadioItem<CarrierStatus>[] = Object.values(CarrierStatus).map(
  (carrierStatus: CarrierStatus) => ({
    label: readableCarrierStatusShort(carrierStatus),
    value: carrierStatus,
  }),
);

export const CARRIERS_SELECT_PER_PAGE_SIZE = 100;
