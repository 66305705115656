/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** рейс */
export interface Trip {
  /**
   * Номер рейса
   * @min 1
   * @example 7
   */
  number: number;
  /** Статус рейса */
  status?: TripStatus;
  /**
   * Номер заявки
   * @min 1
   * @example 7
   */
  transportationOrderNumber?: number;
  /** заявка на грузоперевозку */
  transportationOrder?: TransportationOrder;
  /**
   * Фактическое время выезда с первой точки
   * @format date-time
   * @example 2021-08-01T23:59:59Z
   */
  factFirstDepartureAt?: string;
  /**
   * Фактическое время прибытия на последнюю точку
   * @format date-time
   * @example 2021-08-01T23:59:59Z
   */
  factLastArrivalAt?: string;
  tripDrivers: TripDriver[];
  tripVehicles: TripVehicle[];
  /** События по рейсу */
  tripEvents?: TripEvent[];
  /** Ожидаемое событие по рейсу */
  expectedEvent?: TripEvent;
  /**
   * процент утилизации
   * @min 1
   * @example 42
   */
  utilization?: number;
}

/**
 * Статус рейса
 * @example "TRIP_STATUS_ON_THE_WAY"
 */
export enum TripStatus {
  TRIP_STATUS_AT_LOG_POINT = 'TRIP_STATUS_AT_LOG_POINT',
  TRIP_STATUS_CANCELED = 'TRIP_STATUS_CANCELED',
  TRIP_STATUS_COMPLETED = 'TRIP_STATUS_COMPLETED',
  TRIP_STATUS_CREATED = 'TRIP_STATUS_CREATED',
  TRIP_STATUS_ON_THE_WAY = 'TRIP_STATUS_ON_THE_WAY',
  TRIP_STATUS_WAITING_FOR_INPUT = 'TRIP_STATUS_WAITING_FOR_INPUT',
  TRIP_STATUS_WAITING_FOR_LOADING = 'TRIP_STATUS_WAITING_FOR_LOADING',
}

/** Связь рейса и водителя */
export interface TripDriver {
  /**
   * Уникальный идентификатор записи
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426655440000
   */
  id?: string;
  /**
   * Номер рейса
   * @min 1
   * @example 7
   */
  tripNumber: number;
  /**
   * Уникальный идентификатор водителя
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426655440000
   */
  driverId: string;
  /** Водитель */
  driver: Driver;
  /**
   * Уникальный идентификатор конечной точки до которой едет водитель
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426655440000
   */
  finalSchedulePointId: string;
  /**
   * Позиция водителя в рейсе
   * @example 1
   */
  position: number;
}

/** Данные о водителе */
export interface Driver {
  /**
   * Уникальный идентификатор водителя
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426655440000
   */
  id: string;
  /**
   * Имя водителя
   * @example Василий
   */
  firstName: string;
  /**
   * Фамилия водителя
   * @example Чапаев
   */
  surname: string;
  /**
   * Отчество водителя
   * @example Иванович
   */
  middleName?: string;
  /**
   * Является ли отчество существующим
   * @example false
   */
  isMiddleNameExist: boolean;
  /**
   * Код страны для телефона водителя
   * @example +7
   */
  primaryPhoneCountryCode: string;
  /**
   * Номер телефона водителя
   * @example 1234567890
   */
  primaryPhoneNumber: string;
}

/** связь рейса и автомобиля */
export interface TripVehicle {
  /**
   * Уникальный идентификатор записи
   * @format uuid
   * @example 123e4567-e89b-12d3-a456-426655440000
   */
  id?: string;
  /**
   * Номер рейса
   * @min 1
   */
  tripNumber: number;
  /**
   * Уникальный идентификатор автомобиля
   * @format uuid
   */
  vehicleId: string;
  /** Автомобиль */
  vehicle: Vehicle;
  /**
   * Уникальный идентификатор конечной точки до которой едет автомобиль
   * @format uuid
   */
  finalSchedulePointId: string;
  /**
   * Позиция автомобиля в рейсе
   * @example 1
   */
  position: number;
}

/** автомобиль */
export interface Vehicle {
  /**
   * Уникальный идентификатор автомобиля
   * @format uuid
   */
  id: string;
  /**
   * Регистрационный номер
   * @example 123456
   */
  regNumber: string;
  /**
   * Уникальный идентификатор модели автомобиля
   * @format uuid
   */
  modelId: string;
  /** Данные по модели */
  model: Model;
}

/** модель автомобиля */
export interface Model {
  /**
   * Уникальный идентификатор модели автомобиля
   * @format uuid
   */
  id: string;
  /**
   * Марка
   * @example Toyota
   */
  brand: string;
  /**
   * Модель
   * @example Camry
   */
  model: string;
  /**
   * Тип транспортного средства
   * @example VEHICLE_TYPE_CAR
   */
  vehicleType: ModelVehicleTypeEnum;
  /**
   * Грузоподъемность, гм
   * @example 100
   */
  loadCapacity: number;
  /**
   * Кубатура, мм3
   * @example 1000
   */
  cargoSpace?: number;
}

/** событие в расписании рейса */
export interface TripEvent {
  /**
   * Уникальный идентификатор события
   * @format uuid
   * @example 3fa85f64-5717-4562-b3fc-2c963f66afa6
   */
  id: string;
  /**
   * Номер рейса
   * @min 1
   * @example 1
   */
  tripNumber: number;
  /** Точка маршрута */
  schedulePoint: SchedulePoint;
  /** Данные о водителе */
  driver: Driver;
  /** автомобиль */
  vehicle: Vehicle;
  /** автомобиль */
  vehicleTrailer?: Vehicle;
  /**
   * Планируемое время наступления события
   * @format date-time
   * @example 2023-07-28T10:00:00Z
   */
  planTimeAt: string;
  /**
   * Фактическое время наступления события
   * @format date-time
   * @example 2023-07-28T10:00:00Z
   */
  factTimeAt?: string;
  /**
   * процент утилизации
   * @min 1
   * @example 42
   */
  utilization?: number;
  /** Причина задержки */
  delayReason?: TripEventDelayReason;
  /**
   * Комментарий
   * @example Это норм
   */
  comment?: string;
  /**
   * Тип события
   * @example TRIP_EVENT_TYPE_ARRIVAL
   */
  eventType: TripEventEventTypeEnum;
  /**
   * Время до события по рейсу в сек
   * @min 0
   * @max 2592000
   * @example 12331
   */
  durationUntilEvent?: number;
}

/**
 * Причина задержки
 * @example "DELAY_REASON_CARRIER_DRIVER_FAULT"
 */
export enum TripEventDelayReason {
  DELAY_REASON_CARRIER_TRAFFIC = 'DELAY_REASON_CARRIER_TRAFFIC',
  DELAY_REASON_CARRIER_VEHICLE_BREAKDOWN = 'DELAY_REASON_CARRIER_VEHICLE_BREAKDOWN',
  DELAY_REASON_CARRIER_ROAD_ACCIDENT = 'DELAY_REASON_CARRIER_ROAD_ACCIDENT',
  DELAY_REASON_CARRIER_DRIVER_FAULT = 'DELAY_REASON_CARRIER_DRIVER_FAULT',
  DELAY_REASON_CARRIER_REPLACEMENT_VEHICLE_OR_DRIVER = 'DELAY_REASON_CARRIER_REPLACEMENT_VEHICLE_OR_DRIVER',
  DELAY_REASON_CARRIER_DELAY_FROM_LAST_TRIP = 'DELAY_REASON_CARRIER_DELAY_FROM_LAST_TRIP',
  DELAY_REASON_CARRIER_ARRIVAL_DELAY = 'DELAY_REASON_CARRIER_ARRIVAL_DELAY',
  DELAY_REASON_WAREHOUSE_LOAD_PROBLEMS = 'DELAY_REASON_WAREHOUSE_LOAD_PROBLEMS',
  DELAY_REASON_WAREHOUSE_DOCUMENTATION = 'DELAY_REASON_WAREHOUSE_DOCUMENTATION',
  DELAY_REASON_WAREHOUSE_LONG_LOAD = 'DELAY_REASON_WAREHOUSE_LONG_LOAD',
  DELAY_REASON_WAREHOUSE_SORTING_CENTER_PROBLEMS = 'DELAY_REASON_WAREHOUSE_SORTING_CENTER_PROBLEMS',
  DELAY_REASON_TRANSPORTATION_LONG_DISTANCE_LOGISTICS = 'DELAY_REASON_TRANSPORTATION_LONG_DISTANCE_LOGISTICS',
}

/** заявка на грузоперевозку */
export interface TransportationOrder {
  /**
   * Номер заявки
   * @min 1
   * @example 7
   */
  number?: number;
  /** Статус заявки */
  status: TransportationOrderStatus;
  /**
   * Идентификатор перевозчика
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  carrierId?: string;
  /** Перевозчик */
  carrier?: Carrier;
  /**
   * Идентификатор договора
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  contractId?: string;
  /** контракт с перевозчиком */
  contract?: Contract;
  /**
   * Цена, копейки
   * @min 0
   * @max 1000000000
   * @example 100
   */
  price?: number;
  /**
   * Номер рейса
   * @min 1
   * @example 7
   */
  tripNumber?: number;
  /**
   * Объем перевозимого груза, м3
   * @format double
   * @min 0
   * @max 15000
   * @example 10.5
   */
  cargoVolume: number;
  /** Тип перевозки */
  type: TransportationType;
  /** Расписание перевозки */
  schedule: Schedule;
  /** Предложения на осуществление перевозки */
  offers?: Offer[];
}

/**
 * Статус заявки
 * @example "TRANSPORTATION_ORDER_STATUS_DRAFT"
 */
export enum TransportationOrderStatus {
  TRANSPORTATION_ORDER_STATUS_APPROVED = 'TRANSPORTATION_ORDER_STATUS_APPROVED',
  TRANSPORTATION_ORDER_STATUS_CANCELED = 'TRANSPORTATION_ORDER_STATUS_CANCELED',
  TRANSPORTATION_ORDER_STATUS_CARRIER_SELECTION = 'TRANSPORTATION_ORDER_STATUS_CARRIER_SELECTION',
  TRANSPORTATION_ORDER_STATUS_DRAFT = 'TRANSPORTATION_ORDER_STATUS_DRAFT',
}

/**
 * Тип события
 * @example "TRIP_EVENT_TYPE_ARRIVAL"
 */
export enum TripEventType {
  TRIP_EVENT_TYPE_ARRIVAL = 'TRIP_EVENT_TYPE_ARRIVAL',
  TRIP_EVENT_TYPE_DEPARTURE = 'TRIP_EVENT_TYPE_DEPARTURE',
}

/**
 * Тип перевозки
 * @example "TRANSPORTATION_TYPE_LONG_DISTANCE_TRANSPORTATION"
 */
export enum TransportationType {
  TRANSPORTATION_TYPE_INTRACITY_TRANSPORTATION = 'TRANSPORTATION_TYPE_INTRACITY_TRANSPORTATION',
  TRANSPORTATION_TYPE_LONG_DISTANCE_TRANSPORTATION = 'TRANSPORTATION_TYPE_LONG_DISTANCE_TRANSPORTATION',
}

/** Расписание перевозки */
export interface Schedule {
  /**
   * Уникальный идентификатор расписания
   * @format uuid
   */
  id?: string;
  /**
   * Общая продолжительность, сек
   * @min 0
   * @max 2592000
   * @example 3600
   */
  totalDuration: number;
  /**
   * Общая дистанция в метрах
   * @format double
   * @min 0
   * @max 50000000
   * @example 1200.5
   */
  totalDistance: number;
  /** Точка маршрута */
  firstSchedulePoint: SchedulePoint;
  /**
   * Время начала действия расписания
   * @format date-time
   * @example 2021-08-01T00:00:00Z
   */
  startAt: string;
  /**
   * Время окончания действия расписания
   * @format date-time
   * @example 2021-08-01T23:59:59Z
   */
  endAt: string;
  /**
   * Планируемое время отправления из первой точки
   * @format date-time
   * @example 2021-08-01T08:00:00Z
   */
  planFirstDepartureAt?: string;
  /**
   * Планируемое время прибытия в последнюю точку
   * @format date-time
   * @example 2021-08-01T18:00:00Z
   */
  planLastArrivalAt?: string;
  /** Сегменты расписания */
  segments: ScheduleSegment[];
  /** Точки расписания */
  points?: SchedulePoint[];
  /**
   * Признак круговой перевозки
   * @example true
   */
  isRoundTrip: boolean;
  /**
   * Количество точек в расписании
   * @min 0
   * @max 500
   * @example 3
   */
  schedulePointsCount: number;
}

/** Точка маршрута */
export interface SchedulePoint {
  /**
   * Уникальный идентификатор точки маршрута.
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id: string;
  /**
   * Идентификатор расписания.
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  scheduleId?: string;
  /**
   * Идентификатор логистического пункта.
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  logisticPointId: string;
  /** данные логистического пункта */
  logisticPoint?: LogisticPoint;
  /**
   * Время прибытия на точку.
   * @format date-time
   * @example 2021-08-01T23:59:59Z
   */
  planArriveAt: string;
  /**
   * Время отбытия с точки.
   * @format date-time
   * @example 2021-08-01T23:59:59Z
   */
  planDepartAt: string;
  /**
   * Позиция точки в маршруте.
   * @min 1
   * @max 1000
   * @example 1
   */
  position: number;
  /**
   * Признак прибытия на точку.
   * @example true
   */
  isArrived?: boolean;
}

export interface ScheduleSegment {
  /**
   * Уникальный идентификатор сегмента расписания
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * Идентификатор начальной точки маршрута
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  startSchedulePointId: string;
  /**
   * Идентификатор конечной точки маршрута
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  endSchedulePointId: string;
  /**
   * Позиция сегмента в расписании
   * @min 1
   * @max 1000
   * @example 1
   */
  position: number;
  /**
   * Продолжительность транзита, сек
   * @min 0
   * @max 2592000
   * @example 3600
   */
  planTransitDuration: number;
  /**
   * Длина сегмента маршрута в метрах
   * @format double
   * @min 0
   * @max 25000000
   * @example 10000
   */
  planLength: number;
}

/** Предложение на осуществление перевозки */
export interface Offer {
  /**
   * Уникальный идентификатор предложения
   * @min 1
   * @example 7
   */
  number: number;
  /**
   * Цена, копейки
   * @min 0
   * @max 1000000000
   * @example 100
   */
  price: number;
  /**
   * Объем перевозимого груза, м3
   * @format double
   * @min 0
   * @max 15000
   * @example 10.5
   */
  cargoVolume?: number;
  /** Расписание перевозки */
  schedule: Schedule;
  /** Перевозчик */
  carrier: Carrier;
  /** контракт с перевозчиком */
  contract: Contract;
  /** Статус предложения перевозчику */
  status: OfferStatus;
  /** Резолюция */
  resolution?: OfferResolution;
}

/**
 * Статус предложения перевозчику
 * @example "OFFER_STATUS_OPEN"
 */
export enum OfferStatus {
  OFFER_STATUS_CLOSED = 'OFFER_STATUS_CLOSED',
  OFFER_STATUS_OPEN = 'OFFER_STATUS_OPEN',
}

/**
 * Резолюция
 * @example "OFFER_RESOLUTION_ACCEPTED"
 */
export enum OfferResolution {
  OFFER_RESOLUTION_ACCEPTED = 'OFFER_RESOLUTION_ACCEPTED',
  OFFER_RESOLUTION_REJECTED = 'OFFER_RESOLUTION_REJECTED',
}

/** Перевозчик */
export interface Carrier {
  /**
   * Уникальный идентификатор перевозчика
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id: string;
  /**
   * true - это собственный перевозчик
   * @example true
   */
  isOwn: boolean;
  /**
   * Наименование перевозчика
   * @example ООО Рога и копыта
   */
  companyName: string;
  /**
   * ИНН перевозчика
   * @example 1234567890
   */
  inn: string;
}

/** контракт с перевозчиком */
export interface Contract {
  /**
   * Уникальный идентификатор договора
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id: string;
  /**
   * Номер договора
   * @example 123456
   */
  number: string;
  /**
   * Наименование договора
   * @example Договор о перевозке
   */
  name: string;
  /**
   * Процент НДС по договору
   * @example 20
   */
  vatPercentage: number;
  /**
   * Не облагается НДС
   * @example true
   */
  isNotVat: boolean;
  /**
   * Включена ли НДС в стоимость
   * @example true
   */
  isVatInAmount: boolean;
}

/** адрес логистического пункта */
export interface Address {
  /**
   * ID адреса
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /**
   * Полный адрес логистического пункта
   * @example Россия, Москва, ул. Тверская, 7
   */
  fullAddress: string;
  /**
   * населенный пункт
   * @example Москва
   */
  city: string;
  /**
   * широта
   * @format double
   * @example 55.75
   */
  latitude: number;
  /**
   * долгота
   * @format double
   * @example 43.25
   */
  longitude: number;
  /**
   * таймзона
   * @example Europe/Moscow
   */
  timezone: string;
}

/**
 * Статус логистического пункта
 * @example "LOGISTIC_POINT_STATUS_OPEN"
 */
export enum LogisticPointStatus {
  LOGISTIC_POINT_STATUS_CLOSED = 'LOGISTIC_POINT_STATUS_CLOSED',
  LOGISTIC_POINT_STATUS_OPEN = 'LOGISTIC_POINT_STATUS_OPEN',
}

/**
 * Тип логистического пункта
 * @example "LOGISTIC_POINT_TYPE_PICKUP_POINT"
 */
export enum LogisticPointType {
  LOGISTIC_POINT_TYPE_CROSSDOCK = 'LOGISTIC_POINT_TYPE_CROSSDOCK',
  LOGISTIC_POINT_TYPE_DISTRIBUTION_CENTER = 'LOGISTIC_POINT_TYPE_DISTRIBUTION_CENTER',
  LOGISTIC_POINT_TYPE_DROPOFF_POINT = 'LOGISTIC_POINT_TYPE_DROPOFF_POINT',
  LOGISTIC_POINT_TYPE_FULLFILLMENT = 'LOGISTIC_POINT_TYPE_FULLFILLMENT',
  LOGISTIC_POINT_TYPE_PICKUP_POINT = 'LOGISTIC_POINT_TYPE_PICKUP_POINT',
  LOGISTIC_POINT_TYPE_SELLER = 'LOGISTIC_POINT_TYPE_SELLER',
  LOGISTIC_POINT_TYPE_SORTING_CENTER = 'LOGISTIC_POINT_TYPE_SORTING_CENTER',
}

/** данные логистического пункта */
export interface LogisticPoint {
  /**
   * ID логистического пункта
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** Юридическое лицо */
  legalEntity: LegalEntity;
  /** адрес логистического пункта */
  address: Address;
  /** расписание работы логистического пункта */
  schedule: LogisticPointSchedule[];
  /**
   * Название логистического пункта
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  name: string;
  /**
   * Сокращенное название логистического пункта
   * @example КЗН-1
   */
  shortName: string;
  /** Тип логистического пункта */
  type: LogisticPointType;
  /** Статус логистического пункта */
  status: LogisticPointStatus;
}

/** расписание работы логистического пункта */
export interface LogisticPointSchedule {
  /**
   * ID расписания
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** День недели */
  dayOfWeek: DayOfWeek;
  /**
   * Время открытия в формате hh:mm
   * @example 07:00
   */
  openedAt: string;
  /**
   * Время закрытия в формате hh:mm
   * @example 19:00
   */
  closedAt: string;
  /**
   * Время начала перерыва в формате hh:mm
   * @example 07:00
   */
  breakStartAt?: string;
  /**
   * Время окончания перерыва в формате hh:mm
   * @example 07:00
   */
  breakEndAt?: string;
}

/**
 * День недели
 * @example "DAY_OF_WEEK_MONDAY"
 */
export enum DayOfWeek {
  DAY_OF_WEEK_FRIDAY = 'DAY_OF_WEEK_FRIDAY',
  DAY_OF_WEEK_MONDAY = 'DAY_OF_WEEK_MONDAY',
  DAY_OF_WEEK_SATURDAY = 'DAY_OF_WEEK_SATURDAY',
  DAY_OF_WEEK_SUNDAY = 'DAY_OF_WEEK_SUNDAY',
  DAY_OF_WEEK_THURSDAY = 'DAY_OF_WEEK_THURSDAY',
  DAY_OF_WEEK_TUESDAY = 'DAY_OF_WEEK_TUESDAY',
  DAY_OF_WEEK_WEDNESDAY = 'DAY_OF_WEEK_WEDNESDAY',
}

/** Юридическое лицо */
export interface LegalEntity {
  /**
   * ID юр. лица
   * @format uuid
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  id?: string;
  /** Тип юр. лица */
  legalType: LegalEntityType;
  /**
   * Название юр лица
   * @example ООО Ромашка
   */
  name: string;
  /**
   * ИНН
   * @example 1234567890
   */
  inn: string;
  /**
   * КПП
   * @example 1234567890
   */
  kpp?: string;
  /**
   * ОГРН для ЮЛ и ОГРНИП для ИП
   * @example 1234567890
   */
  ogrn: string;
  /**
   * Юридический адрес
   * @example Москва, ул. Ленина, д. 1
   */
  address: string;
  /**
   * Телефон
   * @example +7 (495) 123-45-67
   */
  phone: string;
}

/** Тип юр. лица */
export enum LegalEntityType {
  LEGAL_TYPE_INDIVIDUAL = 'LEGAL_TYPE_INDIVIDUAL',
  LEGAL_TYPE_LEGAL = 'LEGAL_TYPE_LEGAL',
}

/** Фильтр списка рейсов */
export interface TripFilter {
  /**
   * Номер рейса
   * @min 1
   * @example 123
   */
  tripNumber?: number;
  /** Статус рейса */
  tripStatus?: TripStatus[];
  /**
   * Регистрационный номер
   * @example А123АА777
   */
  vehicleRegNumber?: string;
  /** Тип события */
  expectedEventEventType?: TripEventType[];
  /**
   * Дата и время планового отправления с в формате RFC 3339
   * @format date-time
   * @example 2021-10-01T10:30:00.000Z
   */
  schedulePlanFirstDepartureAtFrom?: string;
  /**
   * Дата и время планового отправления по в формате RFC 3339
   * @format date-time
   * @example 2021-10-01T10:30:00.000Z
   */
  schedulePlanFirstDepartureAtTo?: string;
  /**
   * Город отправления
   * @example Москва
   */
  scheduleDepartureCity?: string;
  /**
   * Город назначения
   * @example Санкт-Петербург
   */
  scheduleDestinationCity?: string;
  /**
   * Город промежуточного пункта
   * @example Санкт-Петербург
   */
  scheduleTransitCity?: string;
  /**
   * id перевозчика
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  transportationOrderCarrierId?: string;
  /**
   * является ли рейс круговым
   * @example true
   */
  scheduleIsRoundTrip?: boolean;
  /**
   * код логистического пункта отправления
   * @example МСК-ПВЗ-1
   */
  scheduleDepartureLogisticPointCode?: string;
  /**
   * код логистического пункта назначения
   * @example МСК-ПВЗ-2
   */
  scheduleDestinationLogisticPointCode?: string;
  /**
   * код логистического пункта транзитный
   * @example МСК-ПВЗ-2
   */
  scheduleTransitLogisticPointCode?: string;
  /** список типов заявок */
  transportationOrderType?: TransportationType[];
}

/** Фильтр списка заявок */
export interface TransportationOrderFilter {
  /**
   * дата отправления с первой точки
   * @format date
   * @example 2020-01-01T00:00:00.000Z
   */
  planFirstDepartureAtDate?: string;
  /**
   * время отправления c
   * @format time
   * @example 10:00:00
   */
  planFirstDepartureAtFrom?: string;
  /**
   * время отправления по
   * @format time
   * @example 10:00:00
   */
  planFirstDepartureAtTo?: string;
  /**
   * город отправления
   * @example Москва
   */
  departureCity?: string;
  /**
   * город назначения
   * @example Санкт-Петербург
   */
  destinationCity?: string;
  /**
   * город промежуточного пункта
   * @example Санкт-Петербург
   */
  transitCity?: string;
  /**
   * код логистического пункта отправления
   * @example МСК-ПВЗ-1
   */
  departureLogisticPointCode?: string;
  /**
   * код логистического пункта назначения
   * @example МСК-ПВЗ-2
   */
  destinationLogisticPointCode?: string;
  /**
   * код логистического пункта транзитный
   * @example МСК-ПВЗ-2
   */
  transitLogisticPointCode?: string;
  /** список статусов заявок */
  status?: TransportationOrderStatus[];
  /**
   * номер заявки
   * @min 1
   * @example 123
   */
  number?: number;
  /**
   * является ли рейс круговым
   * @example true
   */
  isRoundTrip?: boolean;
  /**
   * id перевозчика
   * @example b32baf8b-f939-4e27-9bab-f13223399eed
   */
  carrierId?: string;
  /** список типов заявок */
  type?: TransportationType[];
}

/** Сортировка списка заявок */
export interface TransportationOrderSort {
  /**
   * Поле для сортировки
   * @example cargo_volume
   */
  field: TransportationOrderSortFieldEnum;
  /**
   * Направление сортировки
   * @example asc
   */
  direction: TransportationOrderSortDirectionEnum;
}

/** Сортировка списка рейсов */
export interface TripSort {
  /**
   * Поле для сортировки
   * @example transportation_order.cargo_volume
   */
  field: TripSortFieldEnum;
  /**
   * Направление сортировки
   * @example asc
   */
  direction: TripSortDirectionEnum;
}

/** Страница */
export interface Page {
  /**
   * Количество элементов на странице
   * @min 0
   * @max 500
   * @example 10
   */
  size: number;
  /**
   * Номер страницы
   * @min 1
   * @example 4
   */
  number: number;
}

/** Постраничная навигация */
export interface Pagination {
  /** Страница */
  self: Page;
  /** Страница */
  first: Page;
  /** Страница */
  prev?: Page;
  /** Страница */
  next?: Page;
  /** Страница */
  last: Page;
  /**
   * Общее количество элементов
   * @min 0
   * @example 100
   */
  size: number;
}

/** Error response. */
export interface GenericError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка списания
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example Error message
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Error message
   */
  debugMessage: string;
  /**
   * Уникальный ID запроса
   * @example e19c227c-55f3-48a9-9511-ce973bdcd1e2
   */
  traceId: string;
}

/** Возврат ошибки запроса */
export interface GenericBadRequestError {
  /** Код ошибки */
  code: ErrorCode;
  /**
   * Заголовок
   * @example Ошибка создания автомобиля
   */
  title: string;
  /**
   * Подробное описание ошибки
   * @example При попытке создать автомобиль, обнаружены ошибки. Попробуйте исправить их и попробовать снова.
   */
  detail: string;
  /**
   * Текст ошибки для команды разработки
   * @example Field: vehicle.model, message: не указана модель автомобиля
   */
  debugMessage: string;
  /**
   * Уникальный ID запроса
   * @example e19c227c-55f3-48a9-9511-ce973bdcd1e2
   */
  traceId: string;
  /** Список ошибок валидации */
  validationErrors: ValidationErrors;
}

/** Код ошибки */
export enum ErrorCode {
  Value400100 = 400100,
  Value403100 = 403100,
  Value404100 = 404100,
  Value422100 = 422100,
  Value422101 = 422101,
  Value500100 = 500100,
}

/** Список ошибок валидации */
export type ValidationErrors = ValidationError[];

/** Ошибка валидации */
export interface ValidationError {
  /**
   * Наименование поля
   * @example ID
   */
  fieldName: string;
  /**
   * Сообщение с ошибкой валидации
   * @example Укажите ID в формате UUID
   */
  message: string;
}

/**
 * Тип транспортного средства
 * @example "VEHICLE_TYPE_CAR"
 */
export enum ModelVehicleTypeEnum {
  VEHICLE_TYPE_CAR = 'VEHICLE_TYPE_CAR',
  VEHICLE_TYPE_CARGO = 'VEHICLE_TYPE_CARGO',
  VEHICLE_TYPE_PASSENGER = 'VEHICLE_TYPE_PASSENGER',
  VEHICLE_TYPE_SPECIAL = 'VEHICLE_TYPE_SPECIAL',
  VEHICLE_TYPE_TRACTOR = 'VEHICLE_TYPE_TRACTOR',
  VEHICLE_TYPE_TRAILER = 'VEHICLE_TYPE_TRAILER',
}

/**
 * Тип события
 * @example "TRIP_EVENT_TYPE_ARRIVAL"
 */
export enum TripEventEventTypeEnum {
  TRIP_EVENT_TYPE_ARRIVAL = 'TRIP_EVENT_TYPE_ARRIVAL',
  TRIP_EVENT_TYPE_DEPARTURE = 'TRIP_EVENT_TYPE_DEPARTURE',
}

/**
 * Поле для сортировки
 * @example "cargo_volume"
 */
export enum TransportationOrderSortFieldEnum {
  CargoVolume = 'cargo_volume',
  ScheduleSchedulePointsCount = 'schedule.schedule_points_count',
  SchedulePlanFirstDepartureAt = 'schedule.plan_first_departure_at',
  ScheduleTotalDuration = 'schedule.total_duration',
}

/**
 * Направление сортировки
 * @example "asc"
 */
export enum TransportationOrderSortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 * Поле для сортировки
 * @example "transportation_order.cargo_volume"
 */
export enum TripSortFieldEnum {
  TransportationOrderCargoVolume = 'transportation_order.cargo_volume',
  TransportationOrderPlanFirstDepartureAt = 'transportation_order.plan_first_departure_at',
  TransportationOrderPlanLastArrivalAt = 'transportation_order.plan_last_arrival_at',
  TripFactFirstDepartureAt = 'trip.fact_first_departure_at',
  TripFactLastArrivalAt = 'trip.fact_last_arrival_at',
  TripEventPlanTimeAt = 'trip_event.plan_time_at',
}

/**
 * Направление сортировки
 * @example "asc"
 */
export enum TripSortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export interface GetTransportationOrdersParams {
  /** Страница */
  page?: Page;
  /** Фильтр списка заявок на грузоперевозку */
  transportationOrderFilter?: TransportationOrderFilter;
  /** Сортировка списка заявок на грузоперевозку */
  sort?: TransportationOrderSort;
}

export interface GetTripsParams {
  /** Страница */
  page?: Page;
  /** Фильтрация списка рейсов */
  filter?: TripFilter;
  /** Сортировка списка рейсов */
  sort?: TripSort;
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}
