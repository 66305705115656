import type { RouteRecordRaw } from 'vue-router';

import type { GetDriversParams } from '@/shared/api/generated-api/drivers/data-contracts.ts';
import { RouteName } from '@/shared/config/router';

export const route: RouteRecordRaw = {
  path: 'drivers',
  name: RouteName.DRIVERS,
  component: () => import('@/pages/drivers/ui/Drivers.vue'),
};

export const defaultFilter: GetDriversParams = {
  driverFio: '',
  driverPhone: '',
  driverSnils: '',
  carrierId: undefined,
};

export const DRIVERS_PER_PAGE_SIZE = 50;
