/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GenericBadRequestError,
  GenericError,
  GetLogisticPointsParams,
  HttpMethod,
  LogisticPoint,
  Pagination,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export const LogisticPointsRoutesRegExp = {
  getLogisticPoints: {
    regExp: '^\\/api\\/v1\\/logistic-points$',
    method: HttpMethod.GET,
  },
  createLogisticPoint: {
    regExp: '^\\/api\\/v1\\/logistic-points$',
    method: HttpMethod.POST,
  },
  getLogisticPointById: {
    regExp: '^\\/api\\/v1\\/logistic-points\\/\\d+$',
    method: HttpMethod.GET,
  },
  updateLogisticPoint: {
    regExp: '^\\/api\\/v1\\/logistic-points\\/\\d+$',
    method: HttpMethod.PUT,
  },
};

export class LogisticPoints<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Получить список логистических пунктов
   *
   * @tags Logistic-points
   * @name GetLogisticPoints
   * @summary Получение списка логистических пунктов
   * @request GET:/api/v1/logistic-points
   */
  getLogisticPoints = (query: GetLogisticPointsParams, params: RequestParams = {}) =>
    this.request<
      {
        list: LogisticPoint[];
        /** Постраничная навигация */
        pagination: Pagination;
      },
      GenericBadRequestError | GenericError
    >({
      path: `/api/v1/logistic-points`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * @description Создать логистический пункт
   *
   * @tags Logistic-points
   * @name CreateLogisticPoint
   * @summary Создание логистического пункта
   * @request POST:/api/v1/logistic-points
   */
  createLogisticPoint = (data: LogisticPoint, params: RequestParams = {}) =>
    this.request<LogisticPoint, GenericBadRequestError | GenericError>({
      path: `/api/v1/logistic-points`,
      method: 'POST',
      body: data,
      ...params,
    });
  /**
   * @description Получить логистический пункт по ID
   *
   * @tags Logistic-points
   * @name GetLogisticPointById
   * @summary Получение логистического пункта по ID
   * @request GET:/api/v1/logistic-points/{point_id}
   */
  getLogisticPointById = (pointId: string, params: RequestParams = {}) =>
    this.request<LogisticPoint, GenericBadRequestError | GenericError>({
      path: `/api/v1/logistic-points/${pointId}`,
      method: 'GET',
      ...params,
    });
  /**
   * @description Изменить логистический пункт
   *
   * @tags Logistic-points
   * @name UpdateLogisticPoint
   * @summary Изменение логистического пункта
   * @request PUT:/api/v1/logistic-points/{point_id}
   */
  updateLogisticPoint = (pointId: string, data: LogisticPoint, params: RequestParams = {}) =>
    this.request<LogisticPoint, GenericBadRequestError | GenericError>({
      path: `/api/v1/logistic-points/${pointId}`,
      method: 'PUT',
      body: data,
      ...params,
    });
}
